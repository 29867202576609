import { useState } from 'react';


import "./page.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle';

import projects from './projects.json';
import ProfilePic from './ProfilePicture.png';


const Navbar = () => {
    return (
        <nav className="navbar navbar-expand bg-dark">
            <div className="container-fluid --bs-dark">
                <a className="navbar-brand text-white" href="#"><b>Niklas Günther</b></a>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex flex-row justify-content-center text-center">
                    <li className="nav-item me-3">
                        <a className="nav-link active text-white" aria-current="page" href="mailto:NiklasGuenther@gmx.de"><i className="fas fa-envelope"></i></a>
                    </li>
                    <li className="nav-item me-3">
                        <a className="nav-link active text-white" aria-current="page" href="https://www.linkedin.com/in/niklas-günther-336075226"><i className="fab fa-linkedin"></i></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link active text-white" aria-current="page" href="https://github.com/niguen"><i className="fab fa-github"></i></a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

const PersonalIntro = () => {
    let text = "Welcome to Niklas's personal website! Niklas is a software developer focusing on web app creation and AI integration. With a Master's in Business Informatics, he excels in IT consulting, specializing in large-scale system transformations. Explore Niklas's journey at the intersection of technology and business innovation."

    return (
        <div className="personal-intro row align-items-center px-4 px-lg-6" style={{ maxWidth: '100%', margin: '0 auto' }}>
            <div className="intro-text col-lg-8 order-lg-1 order-2 text-center text-lg-start pe-lg-5 ps-lg-5 ps-4 pe-4">
                <p className="fw-bold fs-5">{text}</p>
            </div>
            <div className="col-lg-4 order-lg-2 order-1 d-flex justify-content-center">
                <img className="profile-pic" src={ProfilePic} alt="Profile" />
            </div>
            <hr className="section-divider" />
        </div>
    );
}

const ProjectCard = ({ name, technologies, description, link }) => {
    const technologyList = technologies.map((technology, index) => {
        return (
            <li key={index}>
                {technology}
            </li>
        );
    });

    return (
        <div className="card bg-dark text-white m-2 m-lg-3" style={{transform: "skew(10deg)"}}>
            <div className="card-body" style={{transform: "skew(-10deg)"}}>
                <h2 className="card-title">{name}</h2>
                <p className="card-text">{description}</p>
                <p>Technologies used:</p>
                <ul>
                    {technologyList}
                </ul>
                <div className="d-flex justify-content-end">
                    <a href={link} className="btn btn-light">Link to Project</a>
                </div>
            </div>
        </div>
    );
}



const ProjectOverview = () => {
    return (
        <div className="row justify-content-center px-4 px-lg-6" style={{ maxWidth: '100%', margin: '0 auto' }}>
            <h1 className="text-center">Projects</h1>
            <hr></hr>
            
            {Object.values(projects).map((project, index) => (
                <div className="col-12 col-md-4 col-lg-5" key={index}>
                    <ProjectCard 
                        name={project.name} 
                        technologies={project.technologies} 
                        description={project.description} 
                        link={project.link} 
                    />
                </div>
            ))}
        </div>
    );
}


export default function Site() {

    return (
        <div className='App'>
            <Navbar />
            <div className='content'>
            <PersonalIntro />
            
            <ProjectOverview />
            </div>
        </div>
    )
}